/**
* Description: This file provides the service layer to the pages.



* @author {Cognizant Technology Solution}
*/
/* istanbul ignore file */
import { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toastActions } from "../common/reducers/ErrorToastReducer";
import axios from "axios";
import errorCodesHandling from "../common/components/errorBoundary/errorCodesHandling";
import { userAuthActions } from "../common/reducers/UserAuthReducer";
import expirationTimeGenerator from "../utils/EmailIDGenerator";
import { useMsal } from "@azure/msal-react";
import { loginRequest, loginRequest2 } from "../common/components/auth/auth";

const useHttp = () => {

  /* UseDispatch Function */
  const dispatch = useDispatch();
  const { instance } = useMsal();
  const [loading, setLoading] = useState(true);
  /* UseSelector Function for Get Data From Store */
  const accessToken = useSelector((state) => state.userAuth.accessToken);
  const msalToken = useSelector((state) => state.userAuth.msalToken);
  const user_email = useSelector((state) => state.userAuth.user_email);
  const graph_token = useSelector((state) => state.userAuth.graph_token);
  /* Client Id */
  const client_id = process.env.REACT_APP_APIGEE_CLIENT_ID;
  /* Service URL */
  const apiURL = process.env.REACT_APP_APIGEE_DOMAIN;

  const gileadURL = axios.create({
    baseURL: apiURL,
    params: {preview: window.location.pathname.includes("Preview") ? true : undefined,
      client_id: client_id,
    },
  });

  const sendHttpRequest = useCallback(
    async (responseConfig) => {
      let tokenObject = {};
      if (
        expirationTimeGenerator(msalToken, true) < new Date() ||
        expirationTimeGenerator(graph_token, true) < new Date()
      ) {
        const responseToken = await instance.acquireTokenSilent(loginRequest);
        dispatch(userAuthActions.msalToken(responseToken.accessToken));
        tokenObject["azure_ad_token"] = responseToken.accessToken;
        const responseTokenGraph = await instance.acquireTokenSilent(
          loginRequest2
        );
        dispatch(userAuthActions.graph_token(responseTokenGraph.accessToken));
        tokenObject["ms_graph_token"] = responseTokenGraph.accessToken;
      }
      setLoading(true);
      let difference, cacheTime;
      if (
        responseConfig.isCache &&
        responseConfig?.cachedData !== undefined &&
        Object.keys(responseConfig?.cachedData)?.length > 0 &&
        responseConfig?.cachedData?.timestamp !== undefined
      ) {
        difference = +new Date() - responseConfig?.cachedData?.timestamp;
        cacheTime = Math.floor(difference / 1000);
      } else {
        cacheTime = 0;
      }
      const cacheTimeSpecified =
        responseConfig.isCache && responseConfig.cacheTime
          ? cacheTime < responseConfig.cacheTime
          : true;
      if (
        responseConfig.isCache &&
        responseConfig?.cachedData !== undefined &&
        Object.keys(responseConfig?.cachedData)?.length > 0 &&
        cacheTimeSpecified
      ) {
        setLoading(false);
        return responseConfig.cachedData;
      } else {
        let response;
        try {
          let configuration = {
            method: responseConfig.method ? responseConfig.method : "GET",
            url: responseConfig.url,
            headers:
              responseConfig["type"] === "WEATHER"
                ? { "Content-Type": "application/json" }
                : {
                    "Content-Type": "application/json",
                    azure_ad_token:
                      Object.keys(tokenObject).length === 0
                        ? msalToken
                        : tokenObject["azure_ad_token"],
                    ms_graph_token:
                      Object.keys(tokenObject).length === 0
                        ? responseConfig.ms_graph_token
                        : tokenObject["ms_graph_token"],
                    apigee_token:
                      localStorage.getItem("apigee_token") !== null
                        ? localStorage.getItem("apigee_token")
                        : "",
                    user_email: user_email,
                  },
            redirect: "follow",
          };
          if (responseConfig.method !== undefined) {
            if (responseConfig["type"] === "imageUpload") {
              configuration.data = responseConfig.data;
            } else {
              configuration.data = JSON.stringify(responseConfig.data);
            }
          }
          if (
            responseConfig["type"] !== "WEATHER"
          
          ) {
            if(responseConfig["type"] === "TOPBANNERINACTIVEDATA"){
              setLoading(false);
            }
            response = await gileadURL(configuration);
            if (
              typeof response.data.Response === "object" &&
              Object.keys(response.data.Response || {}).length > 0
            ) {
              response.data.Response.timestamp = +new Date();
            }
          } else {
            response = await axios(configuration);
          }
          const data = response?.data;
          setTimeout(() => {
            setLoading(false);
          }, 300);
          if (
            response?.data?.AccessToken !== undefined &&
            accessToken !== response?.data?.AccessToken
          ) {
            dispatch(userAuthActions.accessToken(response?.data?.AccessToken));
          }
          return data;
        } catch (err) {
          if (
            responseConfig["type"] === "Like" ||
            responseConfig["type"] === "LobCode" ||
            responseConfig["type"] === "bookmark" ||
            responseConfig["type"] === "imageUpload" 
          ) {
            throw new Error("Error occured");
          }
          dispatch(toastActions.errorToast());
          dispatch(toastActions.errorPage());
          dispatch(
            toastActions.errorMessage(errorCodesHandling("Network error"))
          );
          dispatch(toastActions.errorBgColor("danger"));
          setLoading(false);
        }
      }
    }, // eslint-disable-next-line
    []
  );
  return [sendHttpRequest, loading];
};
export default useHttp;
