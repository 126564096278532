/**
 
 App.js contains Router Navigation to navigate through various routing and User Authentication 
 * @author {Cognizant Technology Solution}
 */
/* istanbul ignore file */
import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userAuthActions } from "../common/reducers/UserAuthReducer";
import { MsalProvider, useMsal } from "@azure/msal-react";
import {
  // InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { loginRequest, loginRequest2 } from "../common/components/auth/auth";
import RouterNavigation from "../router/RouterNavigation";
import Progress from "../common/components/progress/Progress";
import expirationTimeGenerator from "../utils/EmailIDGenerator";
import { Spinner } from "react-bootstrap";

function App({ msalInstance }: any) {
  
  const titleGaReport = (value) => {
    if(value.includes("/NewsRoom")){
      return "G.Net NewsRoom"
    }else if(value.includes("/KiteNetNewsRoom")){
      return "KiteNet NewsRoom"
    }else if(value.includes("/KiteNewsDetails")){
      return "KiteNet NewsDetails"
    }else if(value.includes("/NewsDetails")){
      return "G.Net NewsDetails"
    }else if(value.includes("/KiteNet")){
      return "KiteNet Home"
    }else if(value.includes("/UserProfile")){
      return "UserProfile"
    }else if(value.includes("/SearchResult") && window.location.search.includes("pages")){
      return "All G.Net results"
    }else if(value.includes("/SearchResult") && window.location.search.includes("people")){
      return "People only results"
    }else{
      return "G.Net"
    }
  }

  document.title = titleGaReport(window.location.pathname);
  return (
    <>
      <MsalProvider instance={msalInstance}>
        <Pages />
      </MsalProvider>
    </>
  );
}

const Pages = () => {
  const dispatch = useDispatch();
  const msalToken = useSelector((state) => state.userAuth.msalToken);
  const user_email = useSelector((state) => state.userAuth.user_email);
  const graph_token = useSelector((state) => state.userAuth.graph_token);
  const lobCode = useSelector(
    (state) => state.dateTime.userPrimaryLocation.lobCode
  );
  const { instance, accounts, inProgress } = useMsal();
  const getAzureToken = async () => {
    if (inProgress === InteractionStatus.None && accounts.length > 0) {
      instance
        .acquireTokenSilent(loginRequest)
        .then((response) => {
          // Call your API with the access token and return the data you need to save in state
          dispatch(userAuthActions.msalToken(response.accessToken));
          dispatch(userAuthActions.user_email(accounts[0].username));
        })
        .catch(async () => {
          // Catch interaction_required errors and call interactive method to resolve
          // if (e instanceof InteractionRequiredAuthError) {
            await instance.loginRedirect(loginRequest);
          // }
          // throw e;
        });
    }
  };
  const getGraphToken = async () => {
    if (inProgress === InteractionStatus.None && accounts.length > 0) {
    instance.acquireTokenSilent(loginRequest2).then((response) => {
      dispatch(userAuthActions.graph_token(response.accessToken));
    });
  }
  };
  useEffect(async () => {
    await getAzureToken();
  }, [inProgress, accounts, instance]);
  useEffect(() => {
    if (msalToken) {
      let timeDelay = expirationTimeGenerator(msalToken);
      let timer1 = setTimeout(() => {
        getAzureToken();
      }, timeDelay);
      return () => {
        clearTimeout(timer1);
      };
    }
  }, [msalToken]);

  useEffect(() => {
    getGraphToken();
  }, [inProgress, accounts, instance]);
  useEffect(() => {
    if (msalToken) {
    let timeDelay = expirationTimeGenerator(graph_token);
    let timer1 = setTimeout(() => {
      getGraphToken();
    }, timeDelay);
    return () => {
      clearTimeout(timer1);
    };
  }
  }, [graph_token]);

  return (
    <>
      {msalToken && user_email ? (
        <>
          <Progress />
          {lobCode && <RouterNavigation />}{" "}
        </>
      ) : (
        <div className="redirectingMSAL">
          <Spinner animation="border" variant="secondary" />  Redirecting to
          Login Portal
        </div>
      )}
    </>
  );
};
export default App;
