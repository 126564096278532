/**
 *  Description: This file stores the updated count and the State of the Like and Bookmark Button.

 * @author {Cognizant Technology Solution}
 */

// @flow
import { createSlice } from "@reduxjs/toolkit";

const dashBoardState = { 
  showButtonCount: 0,
  showPredictiveButtonCount: 0,
  companyDataItems: [],
  filteredCompanyDataItems: [],
  matchedItem: [],
  uniquePredictiveSearchData: [],
  searchData: "",
  searchIconState: false,
  searchButtonClick: false,
  setCompanyDataCopy: [],
  setFilteredDataCopy: [],
  mediaSizesCount: 0,
  showMoreButtonClicked: false,
  predictiveShowMoreButtonClicked: false,
  wordsFilteredCount : 0,
};

// $FlowFixMe
const dashBoardModalSlice = createSlice({
  name: "dashBoardDataReducer",
  initialState: dashBoardState,
  reducers: {
    updateWordsFilteredCount(state,action){
      state.wordsFilteredCount = action.payload;
    },
    updateMediaSizesCount(state, action) {
      state.mediaSizesCount = action.payload;
    },
    updateDashboardCount(state, action) {
      state.showButtonCount = action.payload;
    },
    updatePredictiveDashboardCount(state, action) {
      state.showPredictiveButtonCount = action.payload;
    },
    updateCompanyDataItems(state, action) {
      state.companyDataItems = action.payload;
    },
    updateFilteredCompanyDataItems(state, action) {
      state.filteredCompanyDataItems = action.payload;
    },
    updateSetCompanyDataCopy(state, action) {
      state.setCompanyDataCopy = action.payload;
    },
    updateSetFilteredDataCopy(state, action) {
      state.setFilteredDataCopy = action.payload;
    },
    updatedMatchedItem(state, action) {
      state.matchedItem = action.payload;
    },
    updateUniquePredictiveSearchData(state, action) {
      state.uniquePredictiveSearchData = action.payload;
    },
    updateSearchData(state, action) {
      state.searchData = action.payload;
    },
    updateSearchIconState(state, action) {
      state.searchIconState = action.payload;
    },
    updateSearchButtonClick(state, action) {
      state.searchButtonClick = action.payload;
    },
    updateShowMoreButtonClicked(state, action) {
      state.showMoreButtonClicked = action.payload;
    },
    updatePredictiveShowMoreButtonClicked(state,action){
      state.predictiveShowMoreButtonClicked = action.payload;
    }
  },
});
export const dashBoardModalActions = dashBoardModalSlice.actions;
export default dashBoardModalSlice.reducer;
